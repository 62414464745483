<script setup lang="ts">
import {useDomainDataStore} from "~/stores/domain"
import {useUserStore} from "~/stores/user"

const {domainData} = useDomainDataStore()
const userStore = useUserStore()
const year = new Date().getFullYear()
</script>

<template>
  <div class="rounded-bento bg-white px-8 py-6 sm:px-16 sm:py-12 text-button-small">
    <div class="flex flex-wrap gap-4 sm:gap-8">
      <MoleculesFooterNewsletterForm v-if="userStore.userData.features.withNewsletter" />
      <div class="basis-[420px] grow flex flex-wrap gap-4" v-consent-module>
        <div class="basis-[200px] grow uppercase">
          <template v-for="(pageGroup, pageGroupIndex) in userStore.pageGroups">
            <ul class="mb-2" v-if="Array.from(pageGroup).length > 0 || pageGroupIndex === 0">
              <template v-if="pageGroupIndex === 0">
                <li>
                  <NuxtLink :to="localePath('contact')" data-must-consent>{{ $t('pages.contact') }}</NuxtLink>
                </li>
              </template>
              <li v-for="pageData in pageGroup" :key="pageData.slug">
                <NuxtLink :to="localePath({name: 'page-slug', params: {slug: pageData.slug}})">{{ pageData.title }}</NuxtLink>
              </li>
            </ul>
          </template>
        </div>
        <div class="basis-[200px] grow" :class="{'text-right': !userStore.userData.features.withNewsletter}">
          <MoleculesSocialLinks color="black" class="mb-2" :class="{'justify-end': !userStore.userData.features.withNewsletter}" />
          <p class="mb-2">
            &copy; {{ year }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
